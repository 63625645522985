<template>
  <b-form class="content-form" v-if="content">
    <h2>{{ $t("intro") }}</h2>
    <b-form-checkbox
      v-model="content.active"
      name="active"
      class="content-switch"
      switch
      ><b>{{ $t("active") }}</b></b-form-checkbox
    >
    <!-- Title / Description -->
    <b-tabs content-class="mt-3" nav-class="no-padding">
      <b-tab v-for="(language, index) in languages" :key="index">
        <template #title>
          <country-flag :country="language" size="normal" />
        </template>
        <b-form-group :label="$t('title')">
          <b-form-input v-model="content.title[language]"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('description')">
          <b-alert show small
            >Use {{ "\{\{ firstName \}\}" }}, to show current user's firstname
            in text!</b-alert
          >
          <quill-editor
            :content="content.message[language]"
            v-model="content.message[language]"
            :options="editorOption"
          />
        </b-form-group>
      </b-tab>
    </b-tabs>
    <h2>{{ $t("Schedule") }}</h2>
    <b-form-checkbox
      v-model="content.schedule.active"
      name="active"
      class="content-switch"
      switch
      ><b>{{ $t("active") }}</b></b-form-checkbox
    >
    <b-form-group label="Select schedule">
      <model-list-select
        v-model="content.schedule.selected"
        :list="schedules"
        option-text="title"
        option-value="title"
        placeholder="Choose schedule"
      ></model-list-select>
    </b-form-group>
  </b-form>
</template>
<script>
import { ModelListSelect } from 'vue-search-select'

export default {
  props: ["content"],
  components: {
    ModelListSelect
  },
  data() {
    return {
      editorOption: {
        placeholder: this.$i18n.t("insertText"),
      },
      selectedSchedule: null,
      schedules: []
    };
  },
  async created() { 
    await this.fetchSchedules()
    this.schedules = this.$store.getters["Schedule/all"]
  },
  computed: {
    introActive() {
      return this.content?.active;
    },
    scheduleActive() { 
      return this.content?.schedule.active
    },
    languages() {
      return this.$store.getters["Languages/all"];
    },
  },
  methods: {
    async fetchSchedules() {
      await this.$store.dispatch("Schedule/fetchAll");
    },
  }
};
</script>
